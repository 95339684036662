import { useState, useEffect } from "react"

export default function useProject(projectId) {
  const [project, setProject] = useState(null)

  const fetchProject = async () => {
    const response = await fetch(
      "/.netlify/functions/fetch-project?id=" + projectId,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    let responseJson = await response.json()

    console.log(responseJson)

    setProject(responseJson)
  }

  useEffect(() => {
    fetchProject()
  }, [])

  return project
}
