import React, { useState } from "react"
import useProject from "../utils/useProject"
import Spinner from "react-spinner-material"

const MeasurementConfirmation = () => {
  const [isLoading, setLoading] = useState(false)
  const [missingCheck, setMissingCheck] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const [isBookingConfirmed, setBookingConfirmed] = useState(false)
  let projectId

  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    projectId = params.get("id") ? params.get("id") : 1234
  }

  const project = useProject(projectId)

  if (!project) return <div>Loading...</div>
  console.log("🚀 ~ project:", project)

  let {
    Email: email,
    "Combined montør date": installationDate,
    "Order phone number": phoneNumber,
    "Order shipping address": address,
  } = project?.project.fields

  const confirmInstallation = async () => {
    console.log("confirm installation")

    if (!isChecked) {
      setMissingCheck(true)
      setTimeout(() => {
        setMissingCheck(false)
      }, 3000)
      return false
    }

    const data = {
      fields: {
        "Installation information confirmed": true,
        "Installation status": "Installation confirmed",
      },
    }

    const response = await fetch(
      "/.netlify/functions/update-project?id=" + projectId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    )

    setBookingConfirmed(true)
  }

  const formatDate = date => {
    if (!date) return ""
    const d = new Date(date)
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
  }

  return (
    <div
      className="measuring-guide-container project"
      style={{ paddingBottom: "80px" }}
    >
      <h1 className="fs--xl lh--s">Bekræftelse på montering</h1>
      <div className=" lh--m" style={{ marginBottom: 25 }}>
        Dine skræddersyede gardiner er klar til, at vores dygtige montør kan
        komme og montere gardinerne hos dig. Vi vil gerne bede dig bekræfte
        nedenstående information.
      </div>
      <div className=" lh--m">
        <div>
          <strong>Dato</strong> - <span>{formatDate(installationDate)}</span>
        </div>
        <div>
          <strong>Addresse</strong> - <span>{address}</span>
        </div>
        <div>
          <strong>Telefon</strong> - <span>{phoneNumber}</span>
        </div>
      </div>
      <div
        className={`lh--m ${missingCheck ? "shake" : ""}`}
        style={{
          marginTop: 25,
          marginBottom: 25,
          padding: 15,
          border: "1px solid #CFA616",
          borderRadius: 5,
          background: "#FFEFCA",
        }}
      >
        <input
          type="checkbox"
          style={{ "-webkitAppearance": "checkbox", marginRight: 10 }}
          onClick={() => setChecked(!isChecked)}
        />
        Jeg bekræfter, at jeg forud for besøget, vil vi bede dig flytte alle
        genstande fra vindueskarme, samt sørge for at gulvet er ryddet 1 meter
        fra væggen/loftet, hvor gardinerne skal monteres. Vi tager forbehold for
        eventuelle udfordringer ved en montering grundet skjulte ledninger, rør,
        gamle vægge mv.
      </div>
      <div className=" lh--m" style={{ marginTop: 25, marginBottom: 25 }}>
        Såfremt datoen for monteringen ikke passer dig, eller oplysninger
        omkring dato, adresse og tlf. ikke stemmer overens, bedes du kontakte os
        på mail: hi@anddrape.com. Hvis du ønsker at aflyse eller flytte din tid
        med montøren, skal dette gøres senest 48 timer før planlagt besøg per
        mail hi@anddrape.com. Ved manglende aflysning vil det koste et start
        gebyr på DKK 3.080,-
      </div>
      <button
        className="button fs--s consultancy-button primary"
        onClick={confirmInstallation}
      >
        <div className="button-text">Bekræft montering</div>
        <div className="button-icon">
          {isLoading && (
            <Spinner radius={13} color={"#fff"} stroke={1} visible={true} />
          )}
          {!isLoading && (
            <svg className="stroke" viewBox="0 0 24 24">
              <path d="M9 18l6-6-6-6"></path>
            </svg>
          )}
        </div>
      </button>
      {isBookingConfirmed && (
        <div className="lh--m">
          Vi har nu modtaget din bekræftelse.
          <br />
          Vi glæder os til at montere dine gardiner.
        </div>
      )}
    </div>
  )
}
export default MeasurementConfirmation
